import { Link, useNavigate } from "react-router-dom";
import React from "react";

import homepage from "../assets/homepage.jpg";
import styles from "./HomePage.module.css";

const HomePage = () => {
	const navigate = useNavigate(); // Use the useNavigate hook

	const handleGetStartedClick = () => {
		navigate("/landing", { state: { activeTab: "signup" } });
	};
	return (
		<div className={styles.parent}>
			<div className={styles.navbar}>
				<div className={styles.smrttr}>
					<h2>Smrttr.</h2>
				</div>
				<div className={styles.subnav}>
					<span className={styles.subnavlinks} href="Home">
						Home
					</span>
					<span className={styles.subnavlinks} style={{ cursor: "default" }}>
						About
					</span>
					<Link className={styles.subnavlinks} to="/landing">
						Login
					</Link>
				</div>
			</div>
			<div className={styles.parentpane}>
				<div className={styles.leftpane}>
					{/* <h1 className={styles["leftpaneheading"]}>Smrttr.</h1> */}
					<p className={styles["leftpane-firstpara"]}>
						Talk to you documents. Generate notes. Build your personal knowledge
						base.
					</p>
					<button
						className={styles["getstarted-btn"]}
						onClick={handleGetStartedClick}
					>
						Get Started
					</button>
					<h3>
						How to use <span className={styles.smrttrText}>Smrttr. ?</span>
					</h3>

					<p className={styles["leftpane-secondpara"]}>
						Step 1. Create a new chat.
					</p>
					<p className={styles["leftpane-secondpara"]}>
						Step 2. Upload document(s) to your chat.
					</p>
					<p className={styles["leftpane-secondpara"]}>
						Step 3. Interact with your documents by asking questions.{" "}
					</p>
				</div>
				<div className={styles.rightpane}>
					<div className={styles["image-container"]}>
						<img src={homepage} alt="" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomePage;
