const handleUnauthorized = (setToken, navigate) => {
	const handleUnauthorizedResponse = (response) => {
		if (response.status === 401) {
			// Remove the token from the context
			setToken(null);

			// Redirect the user to the sign-in page (LandingPage)
			navigate("/", { replace: true });
		}
	};

	return handleUnauthorizedResponse;
};

export default handleUnauthorized;
