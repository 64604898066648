import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthContext } from "../Context/AuthContext";

const PrivateRoute = ({ element: Component }) => {
	const { isAuthenticated } = useAuthContext();

	return isAuthenticated() ? <Component /> : <Navigate to="/landing" />;
};

export default PrivateRoute;
