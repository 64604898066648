import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useAuthContext } from "../Context/AuthContext";

import handleUnauthorized from "../utils/handleUnauthorized";
import styles from "./Chat.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronDown,
	faChevronRight,
	faPlusCircle,
	faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";

import useravatar from "../assets/useravatar.png";

// const API_ENDPOINT = "http://localhost:8000/api/v1/query_response";
const API_ENDPOINT = "https://docsai.cosmaya.co.in/api/v1/query_response";
const UPLOAD_ENDPOINT = "https://docsai.cosmaya.co.in/api/v1/add_documents";
// const UPLOAD_ENDPOINT = "http://localhost:8000/api/v1/add_documents";
// const FETCH_USER_DETAILS_ENDPOINT =
// 	"http://localhost:8000/api/v1/get_user_details";
const FETCH_USER_DETAILS_ENDPOINT =
	"https://docsai.cosmaya.co.in/api/v1/get_user_details";
// const USER_SIGNOUT_END_POINT = "http://localhost:8000/api/v1/signout";
const USER_SIGNOUT_END_POINT = "https://docsai.cosmaya.co.in/api/v1/signout";

// const CHAT_HISTORY_BY_ID_ENDPOINT =
// 	"http://localhost:8000/api/v1/chat_history_byId";
const CHAT_HISTORY_BY_ID_ENDPOINT =
	"https://docsai.cosmaya.co.in/api/v1/chat_history_byId";

function Chat() {
	const [chatInput, setChatInput] = useState("");
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [chatHistory, setChatHistory] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [topics, setTopics] = useState([]);
	const [isGenericDropdownOpen, setIsGenericDropdownOpen] = useState(false);
	const [placeholderText, setPlaceholderText] = useState("Ask a question");
	const [currentTopic, setCurrentTopic] = useState("Generic");
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	//added as part of modifications
	const [userFiles, setUserFiles] = useState({});
	const [selectedFileId, setSelectedFileId] = useState(null);
	const [displayedQuestions, setDisplayedQuestions] = useState([]);
	const [allQuestions, setAllQuestions] = useState([]);
	const [hasDocuments, setHasDocuments] = useState(false);
	const [selectedDocumentName, setSelectedDocumentName] = useState("");
	const [isNewUpload, setIsNewUpload] = useState(false);

	const answerTabRef = useRef(null);
	const fileInputRef = useRef(null);
	const navigate = useNavigate();
	const { token, userId, setToken, username, setUsername } = useAuthContext();
	const handleUnauthorizedResponse = handleUnauthorized(setToken, navigate);
	const chatListRef = useRef(null);

	useEffect(() => {
		fetchUserDetails();
	}, []);

	useEffect(() => {
		if (!hasDocuments) {
			setPlaceholderText("Please upload a document to start chatting");
		} else if (isNewUpload) {
			setPlaceholderText(
				"New document uploaded. Select it from the left pane to start chatting"
			);
		} else if (!selectedFileId) {
			setPlaceholderText(
				"Select a document from the left pane to start chatting"
			);
		} else {
			setPlaceholderText("Ask a question about the selected document");
		}
	}, [hasDocuments, isNewUpload, selectedFileId]);

	useEffect(() => {
		if (chatListRef.current) {
			chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
		}
	}, [chatHistory]);

	useEffect(() => {
		if (selectedFiles.length > 0) {
			handleFileUpload();
		}
	}, [selectedFiles]);

	const toggleDropdown = () => {
		setIsDropdownOpen((prevState) => !prevState);
	};

	const handleFileSelection = async (fileId) => {
		setSelectedFileId(fileId);
		setIsNewUpload(false); // Reset the new upload flag when a file is selected

		const file = userFiles[fileId];

		if (file) {
			setSelectedDocumentName(file.file_name);

			try {
				const historyResponse = await fetch(
					`${CHAT_HISTORY_BY_ID_ENDPOINT}?userId=${userId}&fileId=${fileId}`,
					{
						method: "GET",
						headers: {
							accept: "application/json",
							"id-token": token,
						},
					}
				);

				if (historyResponse.ok) {
					const historyData = await historyResponse.json();
					const chatHistory = historyData.chat_history || [];
					const formattedChatHistory = chatHistory.map((message) => ({
						sender: message.type === "human" ? "You" : "Smrttr",
						content: message.content,
						type: message.type,
					}));
					setChatHistory(formattedChatHistory);
				} else {
					console.error("Error fetching chat history:", historyResponse.status);
					setChatHistory([]);
				}
			} catch (error) {
				console.error("Error fetching chat history:", error);
				setChatHistory([]);
			}

			if (file.questions && file.questions.length > 0) {
				setAllQuestions(file.questions);
				setDisplayedQuestions(file.questions.slice(0, 3));
			} else {
				setAllQuestions([]);
				setDisplayedQuestions([]);
			}
		} else {
			console.error(`File with ID ${fileId} not found in userFiles`);
			setSelectedDocumentName("");
			setChatHistory([]);
			setAllQuestions([]);
			setDisplayedQuestions([]);
		}
	};
	const sendQuestionToEndpoint = async (question) => {
		try {
			setIsLoading(true);

			const loadingMessage = {
				sender: "System",
				content: "Please wait, fetching answer for you...",
				type: "loading",
			};
			setChatHistory((prevHistory) => [...prevHistory, loadingMessage]);

			const queryParams = new URLSearchParams({
				userId: userId,
				user_ask: question,
				topic: "GENERIC", // Hardcoded to "GENERIC"
				fileId: selectedFileId,
			});

			const response = await fetch(
				`${API_ENDPOINT}?${queryParams.toString()}`,
				{
					method: "GET",
					headers: {
						"id-token": token,
						accept: "application/json",
					},
				}
			);

			handleUnauthorizedResponse(response);

			if (response.ok) {
				const data = await response.json();
				const aiResponse = {
					sender: "Smrttr",
					content: data.ai_response,
					type: "ai",
				};
				setChatHistory((prevHistory) =>
					prevHistory
						.filter((message) => message.type !== "loading")
						.concat(aiResponse)
				);

				// Update the questions if new ones are provided
				if (data.questions && data.questions.length > 0) {
					setAllQuestions(data.questions);
					setDisplayedQuestions(data.questions.slice(0, 3));
				}
			} else {
				console.error("Error:", response.status);
				setChatHistory((prevHistory) =>
					prevHistory
						.filter((message) => message.type !== "loading")
						.concat({
							sender: "System",
							content: "An error occurred while fetching the response.",
							type: "error",
						})
				);
			}
		} catch (error) {
			console.error("Error:", error);
			setChatHistory((prevHistory) =>
				prevHistory
					.filter((message) => message.type !== "loading")
					.concat({
						sender: "System",
						content: "An unexpected error occurred.",
						type: "error",
					})
			);
		} finally {
			setIsLoading(false);
		}
	};

	const handleQuestionClick = async (question) => {
		const userMessage = { sender: "You", content: question, type: "human" };
		setChatHistory((prevHistory) => [...prevHistory, userMessage]);

		setAllQuestions((prevQuestions) => {
			const updatedQuestions = prevQuestions.filter((q) => q !== question);
			setDisplayedQuestions(updatedQuestions.slice(0, 3));
			return updatedQuestions;
		});

		await sendQuestionToEndpoint(question);
	};

	const fetchUserDetails = async () => {
		try {
			const response = await fetch(
				`${FETCH_USER_DETAILS_ENDPOINT}?userId=${userId}`,
				{
					method: "GET",
					headers: {
						accept: "application/json",
						"id-token": token,
					},
				}
			);
			if (response.ok) {
				const data = await response.json();
				const fileDetails = data.data.file_details.Generic.file_list;
				setUserFiles(fileDetails);

				// Set topics based on fileDetails
				const topicsArray = Object.values(fileDetails).map((file) => ({
					name:
						file.file_name.slice(0, 30) +
						(file.file_name.length > 30 ? "..." : ""),
					type: "document",
					id: file.file_id,
				}));
				setTopics(topicsArray);
				setHasDocuments(topicsArray.length > 0);
			} else {
				console.error("Error fetching user details:", response.status);
			}
		} catch (error) {
			console.error("Error fetching user details:", error);
		}
	};

	const handleSignOut = async () => {
		try {
			const response = await fetch(
				`${USER_SIGNOUT_END_POINT}?username=${username}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (response.ok) {
				// Clear the token and navigate to the landing page
				setToken(null);
				setUsername(null); // Clear the username
				navigate("/landing");
			} else {
				console.error("Error signing out:", response.status);
			}
		} catch (error) {
			console.error("Error signing out:", error);
		}
	};

	const handleChatInputChange = (event) => {
		setChatInput(event.target.value);
	};

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			handleSendClick();
		}
	};

	const handleSendClick = async () => {
		if (!chatInput.trim() || !selectedFileId) return;

		// Add the user message to the chat history
		const userMessage = {
			sender: "You",
			content: chatInput,
			type: "human",
		};
		setChatHistory((prevHistory) => [...prevHistory, userMessage]);

		await sendQuestionToEndpoint(chatInput);

		// Clear the input
		setChatInput("");
	};
	const handleFileChange = (event) => {
		const files = Array.from(event.target.files).filter(
			(file) => file.type === "application/pdf"
		);

		if (files.length > 0) {
			setSelectedFiles(files);
		} else {
			alert("Please select PDF files only.");
			setSelectedFiles([]);
		}
	};

	const handleFileUpload = async () => {
		if (selectedFiles.length === 0) {
			alert("Something wrong");
			return;
		}
		setPlaceholderText("We are processing your file and it will be ready soon");
		try {
			const formData = new FormData();
			selectedFiles.forEach((file) => {
				formData.append("file_list", file, file.name);
			});
			const response = await fetch(
				`${UPLOAD_ENDPOINT}?userId=${userId}&topic=Generic`,
				{
					method: "POST",
					headers: {
						"id-token": token,
					},
					body: formData,
				}
			);
			handleUnauthorizedResponse(response);
			if (response.ok) {
				await fetchUserDetails();
				setIsGenericDropdownOpen(true);
				setSelectedFiles([]);
				setHasDocuments(true);
				setIsNewUpload(true); // Set this to true after a new upload
			} else {
				console.error("Error uploading files:", response.status);
				alert("Error uploading files");
			}
		} catch (error) {
			console.error("Error uploading files:", error);
			alert("Error uploading files");
		} finally {
			setPlaceholderText("Ask a question");
		}
	};

	const handleAttachmentClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const toggleGenericDropdown = () => {
		setIsGenericDropdownOpen((prevState) => !prevState);
	};

	return (
		<div className={styles.parent}>
			<div className={styles.navbar}>
				<div className={styles.smrttr}>
					<h2>Smrttr.</h2>
				</div>
				<div className={styles.subnav}>
					<div className={styles["avatar-container"]}>
						<img
							src={useravatar}
							alt="User Avatar"
							className={styles["avatar-icon"]}
							onClick={toggleDropdown}
						/>
						<div
							className={`${styles["dropdown-menu"]} ${
								isDropdownOpen ? styles.show : ""
							}`}
						>
							<div className={styles["dropdown-item"]} onClick={handleSignOut}>
								Sign Out
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.container}>
				<div className={styles["left-pane"]}>
					<div className={styles.dropdown}>
						<h2 className={styles["h2-container"]}>
							My Topics
							<FontAwesomeIcon
								icon={faPenToSquare}
								className={styles["compose-icon"]}
								data-tooltip-id="composeTooltip"
								data-tooltip-html="Create your own topic.<br />Coming Soon!!"
							/>
							<Tooltip
								id="composeTooltip"
								place="top"
								style={{ fontSize: "0.5em" }}
							/>
						</h2>
						<div className={styles.dropdownContent}>
							<div className={styles["topic-header"]}>
								<div
									onClick={toggleGenericDropdown}
									className={styles["dropdown-header"]}
								>
									<FontAwesomeIcon
										icon={
											isGenericDropdownOpen ? faChevronDown : faChevronRight
										}
										className={styles["dropdown-arrow"]}
									/>
									Generic
								</div>
								<FontAwesomeIcon
									icon={faPlusCircle}
									className={styles["upload-icon"]}
									onClick={handleAttachmentClick}
									title="Upload your documents"
								/>
								<input
									type="file"
									ref={fileInputRef}
									style={{ display: "none" }}
									onChange={handleFileChange}
								/>
							</div>
							{isGenericDropdownOpen && (
								<ul className={styles["chat-list"]}>
									{topics.map((topic) => (
										<li
											key={topic.id}
											className={`${styles["chat-list-item"]} ${
												topic.id === selectedFileId
													? styles["selected-document"]
													: ""
											}`}
											onClick={() => handleFileSelection(topic.id)}
										>
											{topic.name}
										</li>
									))}
								</ul>
							)}
						</div>
					</div>
				</div>
				<div className={styles["right-pane"]}>
					{(topics.length > 0 || chatHistory.length > 0) && (
						<div className={styles["topic-message"]}>
							You are chatting with topic
							<select
								className={styles["topic-dropdown"]}
								value={currentTopic}
								disabled
							>
								<option value="Generic">Generic</option>
							</select>
							{selectedDocumentName && (
								<span>
									and document
									<span className={styles["topic-message-document"]}>
										"{selectedDocumentName}"
									</span>
								</span>
							)}
						</div>
					)}

					{/* New suggested questions section */}
					{displayedQuestions.length > 0 && (
						<div className={styles["suggested-questions"]}>
							{displayedQuestions.map((question, index) => (
								<button
									key={index}
									className={styles["question-button"]}
									onClick={() => handleQuestionClick(question)}
								>
									{question}
								</button>
							))}
						</div>
					)}

					<div className={styles["question-area"]}>
						<input
							type="text"
							className={styles["question-input"]}
							value={chatInput}
							onChange={handleChatInputChange}
							onKeyDown={handleKeyDown}
							placeholder={placeholderText}
							disabled={!selectedFileId || isLoading}
						/>
						<button
							className={styles["send-btn"]}
							onClick={handleSendClick}
							disabled={!selectedFileId || !chatInput.trim() || isLoading}
						>
							Send
						</button>
					</div>
					<div className={styles["answer-tab"]} ref={answerTabRef}>
						{Array.isArray(chatHistory) &&
							chatHistory.map((item, index) => (
								<div key={index}>
									<p>
										<strong>{item.sender}</strong> {item.content}
									</p>
								</div>
							))}
					</div>
				</div>
			</div>
			<input
				type="file"
				id="file-input"
				multiple
				accept=".pdf"
				onChange={handleFileChange}
				style={{ display: "none" }}
				ref={fileInputRef}
			/>
		</div>
	);
}

export default Chat;
