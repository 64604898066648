import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../Context/AuthContext";
import handleUnauthorized from "../utils/handleUnauthorized";

import landingPageImage from "../assets/landingpage.jpg";
import styles from "./LandingPage.module.css";

import useravatar from "../assets/useravatar.png";

// const SIGNUP_ENDPOINT = "http://localhost:8000/api/v1/signup";
const SIGNUP_ENDPOINT = "https://docsai.cosmaya.co.in/api/v1/signup";
// const SIGNIN_ENDPOINT = "http://localhost:8000/api/v1/signin";
const SIGNIN_ENDPOINT = "https://docsai.cosmaya.co.in/api/v1/signin";

const LandingPage = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { setToken, setUserId, setUsername } = useAuthContext(); // Use useAuthContext to access setToken, setUserId, and setUsername

	const handleUnauthorizedResponse = handleUnauthorized(setToken, navigate);

	const [activeTab, setActiveTab] = useState("signup");
	const [email_id, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [emailError, setEmailError] = useState("");
	const [passwordError, setPasswordError] = useState("");

	useEffect(() => {
		// Check if the state or query parameter indicates the "signin" tab
		if (location.state?.activeTab === "signin") {
			setActiveTab("signin");
		}
	}, [location.state]);

	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};

	const handleEmailChange = (e) => {
		const value = e.target.value;
		setEmail(value);
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailRegex.test(value)) {
			setEmailError("Please enter a valid email address.");
		} else {
			setEmailError("");
		}
	};

	const handlePasswordChange = (e) => {
		const value = e.target.value;
		setPassword(value);
		const passwordRegex =
			/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()]).{8,}$/;
		if (!passwordRegex.test(value)) {
			setPasswordError(
				"Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character."
			);
		} else {
			setPasswordError("");
		}
	};

	const handleSignUp = async (e) => {
		e.preventDefault();
		try {
			const response = await fetch(SIGNUP_ENDPOINT, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ email_id, password }),
			});

			if (response.ok) {
				// Handle successful sign-up
				alert("Sign-up successful! Please sign in to continue.");
				setActiveTab("signin"); // Switch to the sign-in tab
			} else {
				// Handle sign-up error
				const errorData = await response.json();

				if (
					response.status === 400 &&
					errorData.detail === "Email already exists"
				) {
					alert("User ID already exists. Please use a different email.");
				} else {
					alert("An error occurred during sign-up. Please try again.");
				}
			}
		} catch (error) {
			console.error("Error during fetch call:", error); // Log any errors during the fetch call
			alert("An error occurred during sign-up. Please try again.");
		}
	};

	const handleSignIn = async (e) => {
		e.preventDefault();
		try {
			const response = await fetch(SIGNIN_ENDPOINT, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ email_id, password }),
			});
			handleUnauthorizedResponse(response); // Handle 401 Unauthorized response

			if (response.ok) {
				// Handle successful sign-in
				const data = await response.json();
				const tokenObject = data[0];
				const token = tokenObject.id_Token;
				const userId = tokenObject.userId; // Extract userId from the response

				// Store the token, userId, and username in the context
				setToken(token);
				setUserId(userId);
				setUsername(email_id); // Set the username as the email_id
				console.log("Sign-in successful");

				navigate("/chat");
			} else {
				// Handle sign-in error
				const errorData = await response.json();
				console.error("Sign-in failed:", errorData.error);
				alert("Please double check your username and password");
			}
		} catch (error) {
			console.error("Error:", error);
			alert("Please double check your username and password");
		}
	};

	return (
		<div className={styles["content-container"]}>
			<div className={styles.navbar}>
				<div className={styles.smrttr}>
					<h2>Smrttr.</h2>
				</div>
				<div className={styles.subnav}>
					<Link className={styles.subnavlinks} to="/home">
						Home
					</Link>
					<span className={styles.subnavlinks} style={{ cursor: "default" }}>
						About
					</span>
					<img
						src={useravatar} // Path to your avatar image
						alt="User Avatar"
						className={styles["avatar-icon"]}
					/>
				</div>
			</div>
			<div className={styles["image-container"]}>
				<img src={landingPageImage} alt="" />
			</div>
			<div className={styles["tab-container"]}>
				<div className={styles["tab-buttons"]}>
					<button
						className={`${styles["tab-btn"]} ${
							activeTab === "signup" ? styles.active : ""
						}`}
						onClick={() => handleTabClick("signup")}
					>
						Sign Up
					</button>
					<button
						className={`${styles["tab-btn"]} ${
							activeTab === "signin" ? styles.active : ""
						}`}
						onClick={() => handleTabClick("signin")}
					>
						Sign In
					</button>
				</div>
				<div className={styles["tab-content"]}>
					<div
						className={`${styles["tab-pane"]} ${
							activeTab === "signup" ? styles.active : ""
						}`}
						id="signup"
					>
						<form onSubmit={handleSignUp}>
							<input
								type="email"
								placeholder="Email"
								value={email_id}
								onChange={handleEmailChange}
							/>
							{emailError && <div>{emailError}</div>}

							<input
								type="password"
								placeholder="Password"
								value={password}
								onChange={handlePasswordChange}
							/>
							{passwordError && <div>{passwordError}</div>}

							<button type="submit" className={styles["submit-btn"]}>
								Sign Up
							</button>
						</form>
					</div>
					<div
						className={`${styles["tab-pane"]} ${
							activeTab === "signin" ? styles.active : ""
						}`}
						id="signin"
					>
						<form onSubmit={handleSignIn}>
							<input
								type="email"
								placeholder="Email"
								value={email_id}
								onChange={handleEmailChange}
							/>
							{emailError && <div>{emailError}</div>}
							<input
								type="password"
								placeholder="Password"
								value={password}
								onChange={handlePasswordChange}
							/>
							{passwordError && <div>{passwordError}</div>}
							<button type="submit" className={styles["submit-btn"]}>
								Sign In
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LandingPage;
