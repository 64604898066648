// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { AuthProvider } from "./components/Context/AuthContext";
import Chat from "./components/Chat/Chat";
import LandingPage from "./components/LandingPage/LandingPage";
import HomePage from "./components/HomePage/HomePage";
import PrivateRoute from "./components/PrivateRoutes/PrivateRoute";

function App() {
	return (
		<AuthProvider>
			<Router>
				<div className="App">
					<Routes>
						<Route path="/" element={<HomePage />} />
						<Route path="/home" element={<HomePage />} />
						<Route path="/chat" element={<PrivateRoute element={Chat} />} />
						<Route path="/landing" element={<LandingPage />} />
					</Routes>
				</div>
			</Router>
		</AuthProvider>
	);
}
export default App;
