// AuthContext.js
import React, { createContext, useState, useContext } from "react";

// Create the context
const AuthContext = createContext();

// Export the Provider and Consumer components
const AuthProvider = ({ children }) => {
	const [token, setToken] = useState(null);
	const [userId, setUserId] = useState(null); // Add userId state
	const [username, setUsername] = useState(null); // Add username state

	const isAuthenticated = () => !!token;

	return (
		<AuthContext.Provider
			value={{
				token,
				setToken,
				userId,
				setUserId,
				username,
				setUsername,
				isAuthenticated,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

const useAuthContext = () => useContext(AuthContext);

export { AuthProvider, useAuthContext };
